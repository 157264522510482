
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import type { VastgoedexpertsProduct } from "@/store/modules/vastgoedexperts.store";
import { formatDate } from "@/utils/formatters";
import { Partner } from "@/store/modules/partner.store";
import { PartnerRequest } from "@/store/modules/partner-request.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class DossierPartRequestStatus extends Vue {
    @Prop({ required: true }) activityId!: number;

    @Prop({ required: true }) partnerRequests!: PartnerRequest[];

    loading = false;

    formatDate = formatDate;
}
